
/* - - - - - - 8-< - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

.block-ui-anim-fade > .block-ui-container {
  transition: height 0s linear 200ms, opacity 200ms ease 0s;
}

.block-ui-anim-fade.block-ui-active > .block-ui-container {
  /*this resets the initial delay of the height */
  /*and sizes the block to full height at once at the start of the block. */
  transition-delay: 0s;
}

/* - - - - - - 8-< - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */