.block-ui {
  position: relative;
}

body.block-ui {
  /* IE8 doesn't support .block-ui:not(body) */
  
  position: static;
}

body.block-ui > .block-ui-container,
.block-ui-main > .block-ui-container {
  position: fixed;
}

.block-ui-container {
  position: absolute;
  z-index: 10000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
  filter: alpha(opacity=00);
}

.block-ui-active > .block-ui-container {
  height: 100%;
  cursor: wait;
}

.block-ui-active .block-ui-active > .block-ui-container {
  height: 0;
}

.block-ui-visible > .block-ui-container {
  opacity: 1;
  filter: alpha(opacity=100);
}

.block-ui-overlay {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  filter: alpha(opacity=50);
  background-color: white;
}

.block-ui-message-container {
  position: absolute;
  top: 35%;
  left: 0;
  right: 0;
  height: 0;
  text-align: center;
  z-index: 10001;
}

.block-ui-message {
  display: inline-block;
  text-align: left;
  background-color: #333;
  color: #f5f5f5;
  padding: 20px;
  border-radius: 4px;
  font-size: 20px;
  font-weight: bold;
  /* needed for IE */
  
  filter: alpha(opacity=100);
}